import {configureStore,getDefaultMiddleware} from '@reduxjs/toolkit';
// import reducer from './bugs';
import reducer from './combineReducer';
import logger from './middleware/logger';
import func from './middleware/func';
import toast from './middleware/toast';
import api from './middleware/api';

export default function foo() {
   
   return configureStore({
      reducer,
      middleware: [
         ...getDefaultMiddleware(),
         logger("console"), api,toast, func,]
   });
}
