import axios from 'axios';
import * as actions from '../api';
import {apiURL} from './../../env/config';

const api = ({dispatch}) => next => async action => {
  
  
  if(action.type !== actions.apiCallBegan.type ) return next(action);
  
  const {url, method, data, onSuccess, onStart, onError} = action.payload;
  const token = localStorage.getItem("jwtToken");
    if (token && (action.payload.onStart !=='auth/authRequested')) {
      
        // Apply to every request
        var access_token = "JWT " + token;
        axios.defaults.headers.common["access_token"] = access_token;
      } else {
        // Delete auth header
        delete axios.defaults.headers.common["access_token"];
      }

    if(onStart)
    dispatch({type: onStart});
    
    next(action);
    try {
        const response = await axios.request({
            baseURL: apiURL,
            url,
            data, 
            method
        });
        //General
        dispatch(actions.apiCallSuccess(response.data))
        //Specific
        if(onSuccess)
            dispatch({type: onSuccess, payload: response.data});
    }
    catch(error) {
        //General
        dispatch(actions.apiCallFailed(error.message))
        //Specific
        if(onError)
          dispatch({type:onError, payload:error.message});
    }
}

export default api;