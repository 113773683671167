// import {createAction, createReducer, createSlice} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {createSelector} from 'reselect';
import {apiCallBegan} from './api';
import momemt from 'moment';

//USING CREATE SLICE APPROACH

const slice = createSlice({
    name:"users",
    initialState: {
        users:[],
        loading:false,
        // lastFecth:null,
    },
    reducers: {
        usersRequested: (users,action) => {
            users.loading = true;
        },
        usersReceived: (users,action) => {
            users.users = action.payload;
            users.loading = false;
            // users.lastFecth=Date.now();
        },
        usersRequestFailed: (users,action) => {
            users.loading = false;
        },
        createArtist: (users,action) => {

            users.loading = false;
        }
    }
});

export const {usersRequested, usersReceived, usersRequestFailed} = slice.actions;

export default slice.reducer;

//ACTION CREATORS
const url = '/snowafrica';

export const getUsers = () => (dispatch, getState) => {
    dispatch(apiCallBegan({
        url:`${url}/users`,
        onStart: usersRequested.type,
        onSuccess : usersReceived.type,
        onError: usersRequestFailed.type,
    }));

};

export const createArtist = data => apiCallBegan({
    url:`${url}/artist/create`,
    method: "post",
    data,
    onStart: usersRequested.type,
    onSuccess : usersReceived.type,
    onError: usersRequestFailed.type,
});


export const createCurator = data => apiCallBegan({
    url:`${url}/curator/create`,
    method: "post",
    data,
    onStart: usersRequested.type,
    onSuccess : usersReceived.type,
    onError: usersRequestFailed.type,
});

export const createAdmin = data => apiCallBegan({
    url:`${url}/user/createAdmin`,
    method: "post",
    data,
    onStart: usersRequested.type,
    onSuccess : usersReceived.type,
    onError: usersRequestFailed.type,
});

export const deleteUser = data => apiCallBegan({
    url:`${url}/user/delete/${data}`,
    method: "delete",
    data,
    onStart: usersRequested.type,
    onSuccess : usersReceived.type,
    onError: usersRequestFailed.type,
});

export const deleteManyUsers = data => apiCallBegan({
    url:`${url}/user/deletemany/${data}`,
    method: "delete",
    data,
    onStart: usersRequested.type,
    onSuccess : usersReceived.type,
    onError: usersRequestFailed.type,
});

export const risingStar = data => apiCallBegan({
    url:`${url}/artist/rising/`,
    method: "put",
    data,
    onStart: usersRequested.type,
    onSuccess : usersReceived.type,
    onError: usersRequestFailed.type,
});

export const makeRisingStars = data => apiCallBegan({
    url:`${url}/artist/risingStars/`,
    method: "put",
    data,
    onStart: usersRequested.type,
    onSuccess : usersReceived.type,
    onError: usersRequestFailed.type,
});

//ACCESSORS

//SELECTORS
export const getAllUsers = state => 
    state.entities.users.users;


export const getAllArtist = state =>  {
    if(state.entities.users.users === []){
        return [];
    }else {
        return state.entities.users.users.data.filter(user => user.user_type === "11000.000")
    }

}

//USING CACHE SELECTOR (Memolization)
export const getAllCurators = createSelector(
    state => state.entities.users,
    users => users.users.data.filter(user => user.user_type === "10100.000")
);

// export const getAllArtist = createSelector(
//     state => state.entities.users,
//     users => users.users.data.filter(user => user.user_type === "11000.000")
// );

