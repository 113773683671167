import axios from "axios"; 

const setAuthToken = token => {
  if (token) {
    // Apply to every request
    var access_token = "JWT " + token;
    axios.defaults.headers.common["access_token"] = access_token;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["access_token"];
  }
};
export default setAuthToken;