
import React, {useState} from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Input,
  Modal,
  Button
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import {getAllCurators} from '../../store/users';
import { useSelector} from "react-redux";
import DataTable from 'react-data-table-component';

const Curators = () => {

  const curators = useSelector(getAllCurators);
  const [toggleModal, setToggleModal] = useState(false);
  const [curator, setCurator] = useState('');
  const [selectedCurators, setSelectedCurators] = useState([]);
  const [filteredCurators, setFilteredCurators] = useState('');
  const [search, setSearch] = useState('');

  const toggleModalDisplay = (curator) => {
    setToggleModal(!toggleModal);
    setCurator(curator);
  }

  const handleSelectCurators = (e, data) => {
    if (e.target.checked) {
      setSelectedCurators([
        ...selectedCurators,
        data._id,
      ]);
    } else {
      let index = selectedCurators.indexOf(data._id);
        let curatorsArray = [...selectedCurators];
        curatorsArray.splice(index, 1);
        setSelectedCurators([...curatorsArray]);
    }
}

  const columns = [
    // {
    //   name: 'Select',
    //   width:'60px',
    //   selector: (data) => <div className="custom-control custom-control-alternative custom-checkbox">
    //   <input
    //     onChange={(e) => handleSelectCurators(e, data)}
    //     className="custom-control-input"
    //     id={data._id}
    //     checked={selectedCurators.includes(data._id)}
    //     type="checkbox"
    //   />
    //   <label
    //     className="custom-control-label"
    //     htmlFor={data._id}
    //   >
        
    //   </label>
    // </div>,
    //   sortable: false,
    // },
    {
      name: 'Name',
      selector: (data) => <span className="mb-0 text-sm">
      {data.firstname} {data.lastname}
    </span>,
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Picture',
      selector: (data) => <a
      className="avatar avatar-sm"
      href="#pablo"
      id="tooltip742438047"
      onClick={(e) => e.preventDefault()}
    >
      <img
        alt="..."
        className="rounded-circle"
        src={
          data.photoUrl ? data.photoUrl :
          require("../../assets/img/theme/team-1-800x800.jpg")
            .default
        }
      />
    </a>,
    },
    {
      name: 'Phone Number',
      selector: (data) => <span className="mb-0 text-sm">
                            {data.phone ? data.phone : 'Nil'}
                        </span>,
      sortable: false,
    },
    {
      name: 'Address',
      selector: (data) => <span className="mb-0 text-sm">
          {data.shippingAddress ? data.shippingAddress : 'Nil'}
      </span>,
    },
    // {
    //   name: 'Action',
    //   selector: (data) => <div>
    //       <i onClick={()=>toggleModalDisplay(data)} class="fas fa-edit cursor"></i> |
    //       <i class="fas fa-trash cursor"></i>         
    //   </div>,
    // },
  ];

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const filteredData  = filterData(e.target.value);
    setFilteredCurators(filteredData)
  }

  const filterData = (data) => {
    return (
      curators.filter(curator => 
        curator.firstname.toLowerCase().includes(data.toLowerCase()) || 
        curator.lastname.toLowerCase().includes(data.toLowerCase()) ||
        curator.email.toLowerCase().includes(data.toLowerCase()) 
        // user.phone.toLowerCase().includes(data.toLowerCase()) 
        )
    )
  }



  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Curators</h3>
              </CardHeader>
              <div style={{width:'20em',}}>
                <input type="text" className="form-control"
                  value={search} placeholder="Search" onChange={(e)=> {
                    handleSearch(e)
                  }}
                  />
              </div>
              <DataTable
                columns={columns}
                data={(search === '') ? curators : filteredCurators}
                pagination
                highlightOnHover
              />
              
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          isOpen={toggleModal}
          toggle={toggleModalDisplay}
        >
          <div className="modal-header">
            <h3 className="modal-title" id="exampleModalLabel">
               Change User Priviledge
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggleModalDisplay}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body"> 
              <p> Would you like to change the user priviledge for {curator.firstname} {curator.lastname} ?</p>
              <Input type="select" name="select" >
                <option value="">None</option>
                <option value="">Curator</option>
                <option value="">Artist</option>
                <option value="">Admin</option>
              </Input>
              <div className="mb-2"></div>
              <Button color="primary" type="button">
                Save changes
              </Button>        
          </div>
        </Modal>
 
      </Container>
    </>
  );
};

export default Curators;
