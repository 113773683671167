
import React, {useEffect, useState} from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Input,
  Modal,
  Button,
  Tooltip
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import {getProducts, getRate, getCurrencyRate, getAllProducts, updateRate, authorize, bulkAuthorize, bulkDeleteProduct, deleteProduct, deAuthorize, bulkFeatured, makeFeatured, removeFeatured, bulkUnFeatured} from '../../store/products';
import { useDispatch, useSelector} from "react-redux";
import DataTable from 'react-data-table-component';
import Doc from './../../util/DocService.js';
import PdfContainer from './../../util/PdfContainer';



const Product = () => {

  const dispatch = useDispatch();
  const products = useSelector(getAllProducts);
  const rate = useSelector(getRate);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleAuthorizeModal, setToggleAuthorizeModal] = useState(false);
  const [toggleRateModal, setToggleRateModal] = useState(false);
  const [product, setProduct] = useState('');
  const [filteredProducts, setFilteredProducts] = useState('');
  const [search, setSearch] = useState('');
  const [authoriseText, setAuthoriseText] = useState('');
  const [filterAuthorize, setFilterAuthorize] = useState('');
  const [newRate, setRate] = useState('');
  const [tooltipOpen, setTooltipOpen] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const toggle = () => setTooltipOpen(tooltipOpen);

  const toggleModalDisplay = (product) => {
    setToggleModal(!toggleModal);
    setProduct(product);
  }

  const toggleAuthorizeModalDisplay = (product) => {
    setToggleAuthorizeModal(!toggleAuthorizeModal);
    setProduct(product);
  }

  const toggleRateModalDisplay = () => {
    setToggleRateModal(!toggleRateModal);
  }

  const handleChange = e => {
    setAuthoriseText(e.target.value);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const filteredData  = filterData(e.target.value);
    setFilteredProducts(filteredData)
  }

  const handleAuthorizationFilter = (e) => {
    const value =  e.target.value;
    setFilterAuthorize(value);
    if(value === 'Authorize') {
      const filteredData =  filterAuthorizeData(true);
      setSearch(null);
      setFilteredProducts(filteredData)
    }else if(value === 'Unauthorize') {
      const filteredData =  filterAuthorizeData(false)
      setFilteredProducts(filteredData)
    }else if(value === 'Best') {
      const filteredData =  filterAuthorizeData('best')
      setFilteredProducts(filteredData)
    }
    else {
      setSearch('')
    }
  }

  const handleBulkAction = (e) => {
    const value =  e.target.value;
    if(value === 'authorize') {
      if (window.confirm("Do you want to authorize all selected products?")) {
        dispatch(bulkAuthorize({data:selectedProducts}));
      } else {
        console.log('Do nothing');
      }
    }else if(value === 'featured') {
      if (window.confirm("Do you want to make all these selected product featured?")) {
        dispatch(bulkFeatured({data:selectedProducts}));
      } else {
        console.log('Do nothing');
      }
    }else if(value === 'delete') {
      if (window.confirm("Do you want to delete all selected products")) {
        dispatch(bulkDeleteProduct({data:selectedProducts}));
      } else {
        console.log('Do nothing');
      }
    }else if(value === 'unfeatured') {
      if (window.confirm("Do you want to remove all these selected product from being featured?")) {
        dispatch(bulkUnFeatured({data:selectedProducts}));
      } else {
        console.log('Do nothing');
      }
    }
    else {
      console.log('no action')
    }
  }

  //Filter products by authroization
  const filterAuthorizeData = (data) => {
    if(data !== 'best') {
      return (
      products.data.filter(product => 
        product.authorized === (data)
        )
    )} else {
      return (
        products.data.filter(product => 
          product.featured === (true)
          )
      )
    }
  }

  const filterData = (data) => {
    return (
      products.data.filter(product => 
        product.title.toLowerCase().includes(data.toLowerCase()) || 
        product.category.toLowerCase().includes(data.toLowerCase()) ||
        ((product.artist.user_id) && product.artist.user_id.firstname.toLowerCase().includes(data.toLowerCase()) ) ||
        ((product.artist.user_id) && product.artist.user_id.lastname.toLowerCase().includes(data.toLowerCase()) ) ||
        product.status.toLowerCase().includes(data.toLowerCase()) 
        )
    )
  }

  const deAuthorizeProduct = () =>{
    let selectedProduct = {...product};
    selectedProduct.reason = authoriseText;
    selectedProduct.isAuthorized = false;
    dispatch(deAuthorize(selectedProduct));
    setToggleAuthorizeModal(!toggleAuthorizeModal);
  }

  const authorizeProduct = () =>{
    let selectedProduct = {...product};
    selectedProduct.reason = authoriseText;
    selectedProduct.isAuthorized = false;
    dispatch(authorize(product));
    setToggleAuthorizeModal(!toggleAuthorizeModal);
  }

  const removeFeaturedProduct = (data) => {
    console.log(data)
    if (window.confirm(`Do you want to remove "${data.title}" from being featured`)) {
      dispatch(removeFeatured(data._id));
    } else {
      console.log('Do nothing');
    }
  }

  const featuredProduct = (data) => {
    console.log(data)
    if (window.confirm(`Do you want to make "${data.title}" featured?`)) {
      dispatch(makeFeatured(data._id));
    } else {
      console.log('Do nothing');
    }
  }

  const deleteSelectedProduct = (data) => {
    if (window.confirm("Do you want to delete user?")) {
      dispatch(deleteProduct(data._id));
    } else {
      console.log('Do nothing');
    }
  }

  const changeRate = () => {
    dispatch(updateRate({rate:newRate}));
    setToggleRateModal(!toggleRateModal);
  }

  const handleRateChange = (e) => {
    let rate = e.target.value;
    setRate(rate);
  }

  const downloadProduct = () => {

  }

  const handleSelectProducts = (e, data) => {
    if (e.target.checked) {
      setSelectedProducts([
        ...selectedProducts,
        data._id,
      ]);
    } else {
      let index = selectedProducts.indexOf(data._id);
        let productsArray = [...selectedProducts];
        productsArray.splice(index, 1);
        setSelectedProducts([...productsArray]);
    }
}



  const columns = [
    {
      name: 'Select',
      width:'60px',
      selector: (data) => <div className="custom-control custom-control-alternative custom-checkbox">
      <input
        onChange={(e) => handleSelectProducts(e, data)}
        className="custom-control-input"
        id={data._id}
        checked={selectedProducts.includes(data._id)}
        type="checkbox"
      />
      <label
        className="custom-control-label"
        htmlFor={data._id}
      >
        
      </label>
    </div>,
      sortable: false,
    },
    {
      name: 'Artwork',
      selector: (data) => <span className="mb-0 text-sm">
      {data.title}
    </span>,
      sortable: true,
    },
    {
      name: 'Artist',
      selector: (data) => <span className="mb-0 text-sm">
      {data.artist.user_id ? data.artist.user_id.firstname + ' ' +data.artist.user_id.lastname: ' '}
  </span>,
      sortable: true,
    },
    {
      name: 'Product Picture',
      selector: (data) => <a
      className="avatar avatar-sm"
      href="#pablo"
      id="tooltip742438047"
      onClick={(e) => e.preventDefault()}
    >
      <img
        alt="..."
        className="rounded-circle"
        src={
          data.photo ? data.photo :
          require("../../assets/img/theme/team-1-800x800.jpg")
            .default
        }
      />
    </a>,
    },
    {
      name: 'Artist Picture',
      selector: (data) => <a
      className="avatar avatar-sm"
      href="#pablo"
      id="tooltip742438047"
      onClick={(e) => e.preventDefault()}
    >
      <img
        alt="..."
        className="rounded-circle"
        src={
          (data.artist.user_id && data.artist.user_id.photoUrl) ? data.artist.user_id.photoUrl.photo :
          require("../../assets/img/theme/team-1-800x800.jpg")
            .default
        }
      />
    </a>,
      sortable: false,
    },
    {
      name: 'Phone number',
      selector: (data) => <span className="mb-0 text-sm">
      {(data.artist.user_id) ? data.artist.user_id.phone : 'Nil' }
    </span>,
    },
    {
      name: 'Address',
      selector: (data) =>  <span className="mb-0 text-sm">
      {(data.artist.user_id) ? data.artist.user_id.shippingAddress : 'Nil' }
      </span>,
    },
    {
      name: 'Category',
      selector: (data) => <span className="mb-0 text-sm">
      {data.category}
      </span>,
    },
    {
      name: 'Status',
      selector: (data) => <span className="badge-success" style={{"borderRadius": "5px", "padding":"2px", "color":"black"}}>
      {data.status}
  </span>,
    },
    {
      name: 'Action',
      width: 700,
      // minWidth: 5000,
      selector: (data) => <div>
        <p style={{padding:"10px"}}></p>
          {/* <i data-tooltip="Top Tip" class="top"><i onClick={()=>toggleModalDisplay(data)}  className="fas fa-eye cursor"></i></i> |
          <i onClick={()=>toggleAuthorizeModalDisplay(data)} className="fas fa-check cursor"></i> |
          {(data.featured) ? <i onClick={()=>removeFeaturedProduct(data)} className="fas fa-star cursor"></i> : <i onClick={()=>featuredProduct(data)} className="far fa-star cursor"></i>}|
          <i onClick={()=>{deleteSelectedProduct(data)}} className="fas fa-trash cursor"></i> */}
          <div id="demo" className="mt-4">
  <i data-tooltip="Preview" class="top"><i onClick={()=>toggleModalDisplay(data)}  className="fas fa-eye cursor"></i></i>|
  <i data-tooltip="Authorize" class="top"><i onClick={()=>toggleAuthorizeModalDisplay(data)} className="fas fa-check cursor"></i></i>|
  <i data-tooltip="Featured" class="top">{(data.featured) ? <i onClick={()=>removeFeaturedProduct(data)} className="fas fa-star cursor"></i> : <i onClick={()=>featuredProduct(data)} className="far fa-star cursor"></i>}</i>|
  <i data-tooltip="Delete" class="top"><i onClick={()=>{deleteSelectedProduct(data)}} className="fas fa-trash cursor"></i></i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
</div>
      </div>,
    }
  ];



  useEffect(()=>{
    dispatch(getProducts());
    dispatch(getCurrencyRate());
  }, [dispatch]);

  const createPdf = (html) => Doc.createPdf(html);

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Product</h3>
              </CardHeader>
              <div style={{display:'flex', justifyContent: 'space-around'}}>
                <div>
                  <p> 1 USD = {rate} NGN</p><button className="btn btn-primary" onClick={toggleRateModalDisplay}>Change</button>
                </div>
                <div>
                  <input style={{width:'500px'}} type="text" className="form-control"
                    value={search} placeholder="Search" onChange={(e)=> {
                      handleSearch(e)
                    }}
                    />
                </div>
                <div>
                  <p>Bulk Product Actions</p>
                  <Input type="select" 
                    onChange={handleBulkAction} 
                    name="bulkaction" >
                    <option value="">Select Action</option>
                    <option value="authorize">Authorize</option>
                    <option value="featured">Featured</option>
                    <option value="unfeatured">Unfeatured</option>
                    <option value="delete">Delete</option>
                  </Input>
                </div>
                <div>
                  <p>Product Filter</p>
                  <Input type="select" 
                    value={filterAuthorize}
                    onChange={handleAuthorizationFilter} 
                    name="authorizeFilter" >
                    <option value="none">None</option>
                    <option value="Authorize">Authorize</option>
                    <option value="Unauthorize">Unauthorize</option>
                    <option value="Best">Artwork for the month</option>
                  </Input>
                </div>
              </div>
              <DataTable
                // title="Employees"
                columns={columns}
                data={(search === '') ? products.data : filteredProducts}
                pagination
                highlightOnHover
              />
            <Modal
          className="modal-dialog-centered"
          isOpen={toggleModal}
          toggle={toggleModalDisplay}
        >
          <PdfContainer createPdf={createPdf}>
          <div className="modal-header">
            <h3 className="modal-title" id="exampleModalLabel">
               Product information
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggleModalDisplay}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body"> 
            {/* <p><i onClick={downloadProduct}  className="fa fa-download text-primary cursor"></i></p> */}
              
              <p>{product.title}</p>
              <img style={{width:'60%', height:'60%'}} src={
          product.photo ? product.photo :
          require("../../assets/img/theme/team-1-800x800.jpg")
            .default
        } crossorigin="anonymous"/>
            <p><strong>Artist: </strong>{(product.artist && product.artist.user_id )? product.artist.user_id.firstname + ' ' +product.artist.user_id.lastname: ' '}</p>
            <p><strong>Category: </strong>{product.category}</p>
            <p><strong>Description:</strong> {product.description}</p>
            <p><strong>Artist Price:</strong> {(product.price) ? (product.price).toLocaleString() : ''}</p>
            <p><strong>Our Price:</strong> {(product.price) ? (product.price + (20 *478)).toLocaleString() : ''}</p>
            <p><strong>Dimensions:</strong> {product.dimension} (width x height)</p>
            <p><strong>Weight:</strong> {product.weight} Kg</p>
          </div>
          </PdfContainer>
        </Modal>
            <Modal
          className="modal-dialog-centered"
          isOpen={toggleAuthorizeModal}
          toggle={toggleAuthorizeModalDisplay}
        >
          <div className="modal-header">
            <p className="modal-title" id="exampleModalLabel">
               <strong>Authorize</strong> {product.title}?
            </p>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggleAuthorizeModalDisplay}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body"> 
            <Input type="text" 
                value={authoriseText}
                onChange={handleChange} 
                placeholder="Type authorization reason here..."
                name="authorise" >          
              </Input>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              onClick={deAuthorizeProduct}
            >
              Deauthorize
            </Button>
            <Button 
              color="danger" 
              type="button"
              onClick={authorizeProduct}
              >
              Authorize
            </Button>
          </div>
          
        </Modal>

        <Modal
          className="modal-dialog-centered"
          isOpen={toggleRateModal}
          toggle={toggleRateModalDisplay}
        >
          <div className="modal-header">
            <p className="modal-title" id="exampleModalLabel">
              Set Currency Price (NGN to USD)
            </p>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggleRateModalDisplay}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body"> 
            <Input type="text" 
                value={newRate}
                onChange={handleRateChange} 
                name="rate" >          
              </Input>
          </div>
          <div className="modal-footer">           
            <Button 
              color="primary" 
              type="button"
              onClick={changeRate}
              >
              Submit
            </Button>
          </div>
        </Modal>
            </Card>
          </div>
        </Row>
        
      </Container>
    </>
  );
};

export default Product;
