
import React, {useEffect, useState} from "react";
import DataTable from 'react-data-table-component';

// reactstrap components
import {
  Card,
  CardHeader,
  Modal,
  Container,
  Button,
  Row,
  Input
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import {getUsers, getAllUsers, createArtist, createCurator, deleteManyUsers, deleteUser, createAdmin} from '../../store/users';
import { useDispatch, useSelector} from "react-redux";


const Users = () => {

  const dispatch = useDispatch();
  const [toggleModal, setToggleModal] = useState(false);
  const [user, setUser] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState('');
  const [priviledge, setPriviledge] = useState('');
  const [search, setSearch] = useState('');

  const toggleModalDisplay = (user) => {
    setToggleModal(!toggleModal);
    setUser(user);
  }

  const users = useSelector(getAllUsers);


  const handleChange = e => {
    setPriviledge(e.target.value);
  };

  const savePrivilege = () => {
    let userData = {
        user_id: user._id, 
        email: user.email, 
        firstname: user.firstname
      };
       
    if(priviledge === 'artist') {
      dispatch(createArtist(userData));
      dispatch(getUsers());
      setToggleModal(!toggleModal);
    } else if (priviledge === 'curator'){
      dispatch(createCurator(userData));
      dispatch(getUsers());
      setToggleModal(!toggleModal);
    } else if (priviledge === 'admin'){
      dispatch(createAdmin(userData));
      dispatch(getUsers());
      setToggleModal(!toggleModal);
    }
  }

  const deleteSelectedUser = (data) => {
    if (window.confirm("Do you want to delete user?")) {
      dispatch(deleteUser(data._id));
    } else {
      console.log('Do nothing');
    }
  }

  const handleSelectUsers = (e, data) => {
        if (e.target.checked) {
          setSelectedUsers([
            ...selectedUsers,
            data._id,
          ]);
        } else {
          let index = selectedUsers.indexOf(data._id);
            let usersArray = [...selectedUsers];
            usersArray.splice(index, 1);
            setSelectedUsers([...usersArray]);
        }
  }

  const userTypeCheck = usertype => {
    switch (usertype) {
      case "10000.000":
        return "User";
      case "11000.000":
        return "Artist";
      case "10100.000":
        return "Curator";
      case "11100.000":
        return "Curator";
      case "10000.001":
        return "Admin";
      default:
        return "user";
    }
  };

  const columns = [
    {
      name: 'Select',
      width:'60px',
      selector: (data) => <div className="custom-control custom-control-alternative custom-checkbox">
      <input
        onChange={(e) => handleSelectUsers(e, data)}
        className="custom-control-input"
        id={data._id}
        checked={selectedUsers.includes(data._id)}
        type="checkbox"
      />
      <label
        className="custom-control-label"
        htmlFor={data._id}
      >
        
      </label>
    </div>,
      sortable: false,
    },
    {
      name: 'Name',
      selector: (data) => <span className="mb-0 text-sm">
      {data.firstname} {data.lastname}
    </span>,
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Picture',
      selector: (data) => <a
      className="avatar avatar-sm"
      href="#pablo"
      id="tooltip742438047"
      onClick={(e) => e.preventDefault()}
    >
      <img
        alt="..."
        className="rounded-circle"
        src={
          data.photoUrl ? data.photoUrl :
          require("../../assets/img/theme/team-1-800x800.jpg")
            .default
        }
      />
    </a>,
    },
    {
      name: 'Phone Number',
      selector: (data) => <span className="mb-0 text-sm">
                            {data.phone ? data.phone : 'Nil'}
                        </span>,
      sortable: false,
    },
    {
      name: 'Address',
      selector: (data) => <span className="mb-0 text-sm">
          {data.shippingAddress ? data.shippingAddress : 'Nil'}
      </span>,
    },
    {
      name: 'Priviledge',
      selector: (data) => <span className="mb-0 text-sm">
              {userTypeCheck(data.user_type )}
          </span>,
    },
    {
      name: 'Action',
      selector: (data) => 
      <div>
         <p style={{padding:"10px"}}></p>
        <div id="demo" className="mt-4">
        <i data-tooltip="Edit" class="top"><i onClick={()=>toggleModalDisplay(data)} className="fas fa-edit cursor"></i></i> |
        <i data-tooltip="Delete" class="top"><i onClick={()=>{deleteSelectedUser(data)}} className="fas fa-trash cursor"></i> </i>       
        <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
  <i>&nbsp;</i>
          </div>
      </div>,
    },
  ];

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const filteredData  = filterData(e.target.value);
    setFilteredUsers(filteredData)
  }

  const filterData = (data) => {
    return (
      users.data.filter(user => 
        user.firstname.toLowerCase().includes(data.toLowerCase()) || 
        user.lastname.toLowerCase().includes(data.toLowerCase()) ||
        user.email.toLowerCase().includes(data.toLowerCase()) 
        // user.phone.toLowerCase().includes(data.toLowerCase()) 
        )
    )
  }
  const bulkDelete = () => {
    if (window.confirm("Do you want to delete user?")) {
      dispatch(deleteManyUsers({data:selectedUsers}));
    } else {
      console.log('Do nothing');
    }
  }

  useEffect(()=>{
    dispatch(getUsers());
  }, [dispatch]);

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">All Users</h3>
              </CardHeader>
              <div style={{display:'flex', justifyContent: 'space-around'}}>
                <div style={{width:'20em',}}>
                  <input type="text" className="form-control"
                    value={search} placeholder="Search" onChange={(e)=> {
                      handleSearch(e)
                    }}
                    />
                </div>
                <div>
                  <button className="btn btn-primary" onClick={bulkDelete}>Bulk Delete</button>
                </div>
              </div>
              <DataTable
                // title="Employees"
                columns={columns}
                data={(search === '') ? users.data : filteredUsers}
                pagination
                // highlightOnHover
              />
            </Card> 
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          isOpen={toggleModal}
          toggle={toggleModalDisplay}
        >
          <div className="modal-header">
            <h3 className="modal-title" id="exampleModalLabel">
               Change User Priviledge
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggleModalDisplay}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body"> 
              <p> Would you like to change the user priviledge for <br/>{user.firstname} {user.lastname} ?</p>
              <Input type="select" 
                value={priviledge}
                onChange={handleChange} 
                name="priviledge" >
                <option value="none">None</option>
                <option value="curator">Curator</option>
                <option value="artist">Artist</option>
                <option value="admin">Admin</option>
              </Input>
              <div className="mb-2"></div>
              <Button color="primary" type="button" onClick={savePrivilege}>
                Save changes
              </Button>        
          </div>
        </Modal>
      </Container>
    </>
  );
};

export default Users;

