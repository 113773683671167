import {createSlice} from '@reduxjs/toolkit';
import {createSelector} from 'reselect';
import {apiCallBegan} from './api';
import momemt from 'moment';

//USING CREATE SLICE APPROACH

const slice = createSlice({
    name:"transactions",
    initialState: {
        transactions:[],
        loading:false,
        lastFecth:null
    },
    reducers: {
        transactionsRequested: (transactions,action) => {
            transactions.loading = true;
        },
        transactionsReceived: (transactions,action) => {
            transactions.transactions = action.payload;
            transactions.loading = false;
            transactions.lastFecth=Date.now();
        },
        transactionsRequestFailed: (transactions,action) => {
            transactions.loading = false;
        },
    }
});

export const {transactionsRequested, transactionsReceived, transactionsRequestFailed} = slice.actions;

export default slice.reducer;

//ACTION CREATORS
const url = '/snowafrica/transaction/all';



export const getTransactions = () => (dispatch, getState) => {
    // const {lastFecth} = getState().entities.transactions;

    
    // const diffInMinutes = momemt().diff(momemt(lastFecth), 'minutes');

    // if(diffInMinutes > 10 || lastFecth === null) 
    return dispatch(apiCallBegan({
        url,
        onStart: transactionsRequested.type,
        onSuccess : transactionsReceived.type,
        onError: transactionsRequestFailed.type,
    }));

};

// TODOS
// 1. Fix backend 
// 2. Enable selector and last fetch
// 3. Transaction pagination
// 4. Recent transactions


//SELECTORS
// export const getAllTransactions = state => 
//     cons
    // state.entities.transactions.transactions;

