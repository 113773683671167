import React, {useState} from "react";
import DataTable from 'react-data-table-component';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Input,
  Modal,
  Button
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import {getAllArtist, risingStar, makeRisingStars} from '../../store/users';
import { useDispatch, useSelector} from "react-redux";


const Artist = () => {

  const dispatch = useDispatch();
  const [toggleModal, setToggleModal] = useState(false);
  const [artist, setArtist] = useState('');
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [filteredArtist, setFilteredArtist] = useState('');
  const [search, setSearch] = useState('');
  const [filterFeatured, setFilterfeatured] = useState('');


  const toggleModalDisplay = (artist) => {
    setToggleModal(!toggleModal);
    setArtist(artist);
  }

  const toggleStar = (data) => {
    if (window.confirm("Do you change user's rising status?")) {
      dispatch(risingStar({user:data}));
    } else {
      console.log('Do nothing');
    }
  }

  const handleSelectArtist = (e, data) => {
    if (e.target.checked) {
      setSelectedArtists([
        ...selectedArtists,
        data._id,
      ]);
    } else {
      let index = selectedArtists.indexOf(data._id);
        let artistsArray = [...selectedArtists];
        artistsArray.splice(index, 1);
        setSelectedArtists([...artistsArray]);
    }
}

  const columns = [
    {
      name: 'Select',
      width:'60px',
      selector: (data) => <div className="custom-control custom-control-alternative custom-checkbox">
      <input
        onChange={(e) => handleSelectArtist(e, data)}
        className="custom-control-input"
        id={data._id}
        checked={selectedArtists.includes(data._id)}
        type="checkbox"
      />
      <label
        className="custom-control-label"
        htmlFor={data._id}
      >
        
      </label>
    </div>,
      sortable: false,
    },
    {
      name: 'Name',
      selector: (data) => <span className="mb-0 text-sm">
      {data.firstname} {data.lastname}
    </span>,
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Picture',
      selector: (data) => <a
      className="avatar avatar-sm"
      href="#pablo"
      id="tooltip742438047"
      onClick={(e) => e.preventDefault()}
    >
      <img
        alt="..."
        className="rounded-circle"
        src={
          data.photoUrl ? data.photoUrl :
          require("../../assets/img/theme/team-1-800x800.jpg")
            .default
        }
      />
    </a>,
    },
    {
      name: 'Phone Number',
      selector: (data) => <span className="mb-0 text-sm">
                            {data.phone ? data.phone : 'Nil'}
                        </span>,
      sortable: false,
    },
    {
      name: 'Address',
      selector: (data) => <span className="mb-0 text-sm">
          {data.shippingAddress ? data.shippingAddress : 'Nil'}
      </span>,
    },
    {
      name: 'Action',
      selector: (data) => <div>
      <div id="demo" className="m-4">
      <p style={{padding:"10px"}}></p>
          <i data-tooltip="Featured" class="top">{(data.isRising) ? <i onClick={()=>toggleStar(data)} className="fas fa-star cursor"></i> : <i onClick={()=>toggleStar(data)} className="far fa-star cursor"></i>}</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
          <i>&nbsp;</i>
      </div>
      </div>,
    },
  ];

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const filteredData  = filterData(e.target.value);
    setFilteredArtist(filteredData)
  }

  const artists = useSelector(getAllArtist);

  const filterData = (data) => {
    return (
      artists.filter(artist => 
        artist.firstname.toLowerCase().includes(data.toLowerCase()) || 
        artist.lastname.toLowerCase().includes(data.toLowerCase()) ||
        artist.email.toLowerCase().includes(data.toLowerCase()) 
        // user.phone.toLowerCase().includes(data.toLowerCase()) 
        )
    )
  }


  const handleFeaturedFilter = (e) => {
    const value =  e.target.value;
    setFilterfeatured(value);
    if(value === 'Featured') {
      const filteredData =  filterFeaturedData(true);
      setSearch(null);
      setFilteredArtist(filteredData)
    }
    else {
      setSearch('')
    }
  }

  const filterFeaturedData = (data) => {
    return (
      artists.filter(artist => 
        artist.isRising === (data)
        )
    )
  }

  const bulkStar = () => {
    if (window.confirm("Do you change the rising status for all selected artists?")) {
      dispatch(makeRisingStars({data:selectedArtists}));
    } else {
      console.log('Do nothing');
    }
  }

 
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Artists</h3>
              </CardHeader>
              <div style={{display:'flex', justifyContent: 'space-around'}}>
                <div style={{width:'50em',}}>
                  <input type="text" className="form-control"
                    // onChange={(value)=> {
                    //   setSearch(value);
                    // }}
                    value={search} placeholder="Search" onChange={(e)=> {
                      handleSearch(e)
                    }}
                    />
                </div>
                <div>
                  <button className="btn btn-primary" onClick={bulkStar}>Bulk Star</button>
                </div>
                <div>
                    <p>Filter by Featured Artist</p>
                    <Input type="select" 
                      value={filterFeatured}
                      onChange={handleFeaturedFilter} 
                      name="authorizeFilter" >
                      <option value="none">None</option>
                      <option value="Featured">show featured</option>
                    </Input>
                  </div>
                </div>
              <DataTable
                columns={columns}
                data={(search === '') ? artists : filteredArtist}
                pagination
                highlightOnHover
              />
            </Card>
          </div>
        </Row>
        {/* <Modal
          className="modal-dialog-centered"
          isOpen={toggleModal}
          toggle={toggleModalDisplay}
        >
          <div className="modal-header">
            <h3 className="modal-title" id="exampleModalLabel">
               Change User Priviledge
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggleModalDisplay}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body"> 
              <p> Would you like to change the user priviledge for {artist.firstname} {artist.lastname} ?</p>
              <Input type="select" 
                value={priviledge}
                onChange={handleChange} 
                name="priviledge" >
                <option value="none">None</option>
                <option value="curator">Curator</option>
                <option value="artist">Artist</option>
                <option value="admin">Admin</option>
              </Input>
              <div className="mb-2"></div>
              <Button color="primary" type="button" onClick={savePrivilege}>
                Save changes
              </Button>        
          </div>
        </Modal> */}
      </Container>
    </>
  );
};

export default Artist;
