import React from 'react';

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "views/Login.js";
import configureStore from "./store/configureStore";
import  {Provider} from "react-redux";
import { Route, Switch } from "react-router-dom";


const store = configureStore();

export function App() {
    return (
        <Provider store={store}>
            <Switch>
                <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
                {/* <Redirect from="/" to="/admin/index" /> */}
                <Route path="/" render={(props) => <AuthLayout {...props} />} />
            </Switch>
        </Provider>
    )

}