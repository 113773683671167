
import {createSlice} from '@reduxjs/toolkit';
import {createSelector} from 'reselect';
import {apiCallBegan} from './api';
import jwt_decode from "jwt-decode";
import setAuthToken from './utils';

//USING CREATE SLICE APPROACH

const slice = createSlice({
    name:"auth",
    initialState: {
        user:{},
        loading:false,
    },
    reducers: {
        authRequested: (auth,action) => {
            auth.loading = true;
        },
        saveUser: (auth,action) => {
            const { token } = action.payload;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            auth.user = jwt_decode(token);
            console.log('new', auth.user)
        },
        authRequestfailed: (auth,action) => {
            auth.loading = false;
        },
        authLogout: (auth,action) => {
            localStorage.removeItem('jwtToken');
            auth.user = {};
        },
    }
});

export const {saveUser, authLogout, authRequested, authRequestfailed} = slice.actions;

export default slice.reducer;

//ACTION CREATORS
const url = '/snowafrica/loginUser';

export const loginUser = data => apiCallBegan({
        url,
        method: "post",
        data,
        onStart: authRequested.type,
        onSuccess : saveUser.type,
        onError: authRequestfailed.type
});

export const getCurrentUser = createSelector(
    state => state.entities.auth,
    auth => auth.user
);

export const logOut = ()=>{
    localStorage.clear();
}