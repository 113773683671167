import {createSlice} from '@reduxjs/toolkit';
import {createSelector} from 'reselect';
import {apiCallBegan} from './api';
import momemt from 'moment';

//USING CREATE SLICE APPROACH

const slice = createSlice({
    name:"products",
    initialState: {
        products:[],
        loading:false,
        lastFecth:null,
        rate:''
    },
    reducers: {
        productsRequested: (products,action) => {
            products.loading = true;
        },
        productsReceived: (products,action) => {
            products.products = action.payload;
            products.loading = false;
            products.lastFecth=Date.now();
        },
        productsRequestFailed: (products,action) => {
            products.loading = false;
        },
        currencyRequested: (products,action) => {
            products.loading = true;
        },
        currencyReceived: (products,action) => {
            products.rate = action.payload.data.rate;
            products.loading = false;
        },
        currencyRequestFailed: (products,action) => {
            products.loading = false;
        },
    }
});

export const {productsRequested, currencyRequested, currencyReceived, currencyRequestFailed, productsReceived, productsRequestFailed} = slice.actions;

export default slice.reducer;

//ACTION CREATORS
const url = '/snowafrica/product';

export const getProducts = () => (dispatch, getState) => {
    const {lastFecth} = getState().entities.products;
    const diffInMinutes = momemt().diff(momemt(lastFecth), 'minutes');

    if(diffInMinutes > 10 || lastFecth === null) 
    return dispatch(apiCallBegan({
        url,
        onStart: productsRequested.type,
        onSuccess : productsReceived.type,
        onError: productsRequestFailed.type,
        method: 'POST'
    }));

};

export const authorize = data => apiCallBegan({
    url:`${url}/authorize/`,
    method: "put",
    data,
    onStart: productsRequested.type,
    onSuccess : productsReceived.type,
    onError: productsRequestFailed.type,
});

export const bulkAuthorize = data => apiCallBegan({
    url:`${url}/bulk_authorize/`,
    method: "put",
    data,
    onStart: productsRequested.type,
    onSuccess : productsReceived.type,
    onError: productsRequestFailed.type,
});

export const deAuthorize = data => apiCallBegan({
    url:`${url}/deauthorize/`,
    method: "put",
    data,
    onStart: productsRequested.type,
    onSuccess : productsReceived.type,
    onError: productsRequestFailed.type,
});

export const makeFeatured = data => apiCallBegan({
    url:`${url}/makefeatured/`,
    method: "put",
    data,
    onStart: productsRequested.type,
    onSuccess : productsReceived.type,
    onError: productsRequestFailed.type,
});

export const bulkFeatured = data => apiCallBegan({
    url:`${url}/make_bulk_featured/`,
    method: "put",
    data,
    onStart: productsRequested.type,
    onSuccess : productsReceived.type,
    onError: productsRequestFailed.type,
});

export const removeFeatured = data => apiCallBegan({
    url:`${url}/removefeatured/`,
    method: "put",
    data,
    onStart: productsRequested.type,
    onSuccess : productsReceived.type,
    onError: productsRequestFailed.type,
});

export const bulkUnFeatured = data => apiCallBegan({
    url:`${url}/removed_bulk_featured/`,
    method: "put",
    data,
    onStart: productsRequested.type,
    onSuccess : productsReceived.type,
    onError: productsRequestFailed.type,
});

export const bulkDeleteProduct = data => apiCallBegan({
    url:`${url}/bulk_delete`,
    method: "delete",
    data,
    onStart: productsRequested.type,
    onSuccess : productsReceived.type,
    onError: productsRequestFailed.type,
});

export const deleteProduct = data => apiCallBegan({
    url:`${url}/`,
    method: "delete",
    data,
    onStart: productsRequested.type,
    onSuccess : productsReceived.type,
    onError: productsRequestFailed.type,
});

export const updateRate = data => apiCallBegan({
    url: '/snowafrica/currencyrate',
    method: "post",
    data,
    onStart: currencyRequested.type,
    onSuccess : currencyReceived.type,
    onError: currencyRequestFailed.type,
});

export const getCurrencyRate = () => apiCallBegan({
    url: '/snowafrica/currencyrate',
    method: "get",
    onStart: currencyRequested.type,
    onSuccess : currencyReceived.type,
    onError: currencyRequestFailed.type,
});





//ACCESSORS

//SELECTORS
export const getAllProducts = state => 
    state.entities.products.products;

export const getRate = state => 
    state.entities.products.rate;

