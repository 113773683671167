import {combineReducers} from 'redux';
import productReducer from './products.js';
import userReducer from './users';
import authReducer from './auth';


export default combineReducers({
    users: userReducer,
    products: productReducer,
    auth: authReducer
});