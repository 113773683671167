
import Index from "views/Index.js";
// import Login from "views/Login.js";
// import Profile from "views/examples/Profile.js";
// import Maps from "views/examples/Maps.js";
// import Register from "views/examples/Register.js";
// import Login from "views/examples/Login.js";
// import Tables from "views/examples/Tables.js";
import Users from "views/layout/Users.js";
import Curators from "views/layout/Curators.js";
import Artist from "views/layout/Artist.js";
import Product from "views/layout/Product.js";
import Transaction from "views/layout/Transaction.js";
// import Icons from "views/examples/Icons.js";

var routes = [
  
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "All Users",
    icon: "ni ni-planet text-blue",
    component: Users,
    layout: "/admin",
  },
  {
    path: "/curators",
    name: "Curators",
    icon: "ni ni-pin-3 text-orange",
    component: Curators,
    layout: "/admin",
  },
  {
    path: "/artist",
    name: "Artist",
    icon: "ni ni-single-02 text-yellow",
    component: Artist,
    layout: "/admin",
  },
  {
    path: "/product",
    name: "Products",
    icon: "ni ni-bullet-list-67 text-red",
    component: Product,
    layout: "/admin",
  },
  {
    path: "/transaction",
    name: "Transactions",
    icon: "ni ni-key-25 text-info",
    component: Transaction,
    layout: "/admin",
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth",
  // },
];
export default routes;
