
import React, {useState, useEffect} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  // Row,
  Col,
} from "reactstrap";
import { loginUser, getCurrentUser} from "store/auth";
import logo from "./../assets/img/brand/argon-react.jpg";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  const dispatch = useDispatch();
  const auth = useSelector(getCurrentUser);

  const history = useHistory();

  const [data, setData] = useState({
    email:'',
    password:''
  });

  const handleChange = (e) => {
    setData({
        ...data,
        [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser(data))
  }

  useEffect(() => {
    if(auth && auth.user_type === '10000.001') {
      history.push('/admin');
    }
    else if(auth && auth.user_type != '10000.001') toast("You are not an admin!!!")
},[auth, history])

  return (
    <>
    <ToastContainer/>
    <div style={{"display": "flex","justifyContent": "center"}}>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5 my-auto">
          <div style={{"display": "flex","justifyContent": "center"}}>
          <img
              className=""
              src={logo}
              alt="snow logo"
            />
          </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={data.email}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    name="password"
                    type="password"
                    onChange={handleChange}
                    value={data.password}
                  />
                </InputGroup>
              </FormGroup>
              
              <div className="text-center">
                <Button onClick={handleSubmit} className="my-4" color="primary" type="button">
                  Sign in
                </Button>
                <p ><a href="https://snowafrica.art/forgotpassword">Forgot your password</a></p>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
      </div>
    </>
  );
};

export default Login;
